@font-face {
  font-family: "Quicksand";
  src: url(../../assets/fonts/static/Quicksand-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url(../../assets/fonts/static/Quicksand-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url(../../assets/fonts/static/Quicksand-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@use "../../styles/partials/global";
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/media" as *;
@use "../../styles/partials/variables" as *;

.header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  margin: 0 auto 2rem auto;
  background-color: $headerBackground;
  box-shadow: rgba(66, 66, 66, 0.2) 0px 2px 8px 0px;
  border-radius: 0 0 10px 10px;
  padding: 0 3.6rem;
  z-index: 10;

  @include tablet {
    width: 90%;
  }

  @include desktop {
    justify-content: space-between;
  }

  &__logo {
    width: 10rem;
    display: none;
    @include desktop {
      display: block;
    }
  }
}
.menu-container {
  height: 100%;
  justify-self: center;
  text-transform: uppercase;
  font-weight: 400;
  flex-wrap: wrap;
  display: flex;
}

.menu {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 7rem;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  &__link-container {
    height: 100%;
    border-bottom: 3px solid transparent;
  }
}
.icon-container {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  border: 2px solid $darkBlue;
  background-color: $darkBlue;
}

.profile-icon {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(250, 254, 255);
  background-color: $darkBlue;
  text-transform: uppercase;
}

.menu-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;

  &__icon {
    height: 4rem;
    margin-bottom: 0.3rem;

    @include tablet {
      height: 6rem;
    }
  }
}

.menu__link-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.menu-item-text {
  color: $backgroundSalmon;
  font-weight: 600;
  margin: 0;
  opacity: 0;
}

.menu__link-container:hover .menu-item-text {
  opacity: 1;
}

.profile-container {
  width: 10rem;
  display: flex;
  justify-content: flex-end;

  @include tablet {
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
  }
}

//// Colors ////

$backgroundSalmon: rgb(240, 90, 90);
$headerBackground: rgb(250, 254, 255);
$darkBlue: rgb(33, 75, 92);
$lightTeal: rgb(73, 182, 182);
$backgroundYellow: rgb(252, 253, 154);
$ultraLightBlue: rgb(250, 254, 255);
$buttonGold: rgb(255, 189, 66);
$buttonHoverBg: rgb(243, 114, 55);
$buttonHoverBd: rgb(189, 50, 7);
$formBlue: rgb(218, 239, 245);
$blueText: rgb(60, 135, 165);
$loginBlue: rgb(88, 159, 226);
$myItemsBg: rgb(252, 123, 119);
$registerGreen: rgb(142, 228, 102);
$swapBlue: rgb(158, 232, 255);
$matchesGreen: rgb(158, 255, 179);
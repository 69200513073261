@use "../../styles/partials/global";
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/media" as *;
@use "../../styles/partials/variables" as *;



.my-items-container {
  background-color: $myItemsBg;
  height: 100vh;
}
.cards-container {
  margin-top: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: $myItemsBg;
}

.myitems-load {
  background-color: $myItemsBg;
  height: 100vh;
  width: 100%;
}

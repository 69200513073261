@use "../partials/typography";

html {
  font-size: 62.5%;
}

body {
  font-family: "Quicksand";
  font-size: 1.6rem;
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

@use "../../styles/partials/global";
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/media" as *;
@use "../../styles/partials/variables" as *;

.card-container {
  margin-bottom: 4rem;
  width: 80%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  position: relative;
  flex-wrap: wrap;
  min-width: 30rem;
  max-width: 120rem;

  @include tablet {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &:hover {
    box-shadow: none;
    transform: scale(105%);
  }

  &__text {
    width: 60%;
    padding: 0 2rem;
    width: 100%;
    @include tablet {
      border-left: 2px solid rgb(245, 213, 212);
    }
  }

  &__delete {
    position: absolute;
    height: 2rem;
    width: 2rem;
    right: 2rem;
    top: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__image {
    height: 15rem;
    border-radius: 10px;
    margin-right: 2rem;
  }
}

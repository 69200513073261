@use "../../styles/partials/global";
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/media" as *;
@use "../../styles/partials/variables" as *;

.login-form-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $loginBlue;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  min-width: 28rem;
  max-width: 70rem;
  padding: 2.6rem;
  background-color: white;
  box-shadow: rgba(15, 13, 145, 0.6) 0px 2px 8px 0px;
  border-radius: 25px;

  &__username {
    margin-bottom: 2.6rem;
  }

  &__button {
    width: 30%;
    color: white;
    padding: 1.2rem 2.6rem;
    min-width: 10rem;
    background-color: $buttonGold;
    border-radius: 25px;
    border: 1px solid $buttonGold;
    font-family: "QuickSand";
    font-size: 1.6rem;
    border: 2px solid transparent;

    &:hover {
      background-color: $buttonHoverBg;
      box-shadow: rgba(243, 114, 55, 0.6) 3px 3px,
        rgba(243, 114, 55, 0.3) 6px 6px, rgba(243, 114, 55, 0.2) 9px 9px;
      border: 2px solid $buttonHoverBd;
      cursor: pointer;
    }
  }

  &__label {
    margin-bottom: 0.2rem;
  }
}

.login-form__username,
.login-form__password {
  width: 100%;
  background-color: $formBlue;
  border-radius: 25px;
  border: 2px solid $formBlue;
  padding: 1.2rem 2.6rem;
  font-family: "QuickSand";
  font-size: 1.6rem;
  &:focus {
    border: 2px solid $blueText;
    outline: none;
  }
}

.logged-container {
  width: 100%;
  color: $blueText;
  align-self: flex-start;
  padding: 1rem;
  margin: 1.2rem 2.6rem 2.2rem 2.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.forgot-pass {
  margin-left: 0.6rem;
  margin-bottom: 0.2rem;

  @include tablet {
    margin-left: 2.6rem;
  }
}

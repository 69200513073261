$tablet-break: 768px;
$desktop-break: 1280px;

@mixin tablet() {
  @media (min-width: $tablet-break) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $desktop-break) {
    @content;
  }
}

@use "../../styles/partials/global";
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/media" as *;
@use "../../styles/partials/variables" as *;

.list-container {
  height: 100vh;
  background-color: $backgroundYellow;
}

.submit-form-container {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $backgroundYellow;
}

.submit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 2.6rem;
  background-color: $ultraLightBlue;
  box-shadow: rgba(160, 114, 54, 0.2) 0px 2px 8px 0px;
  border-radius: 25px;

  @include tablet {
    width: 45%;
    min-width: 50rem;
    max-width: 60rem;
  }

  &__image-container {
    height: 30%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__button {
    width: 30%;
    color: white;
    padding: 1.2rem 2.6rem;
    background-color: $buttonGold;
    border-radius: 25px;
    border: 1px solid $buttonGold;
    font-family: "QuickSand";
    font-size: 1.6rem;
    border: 2px solid transparent;
    min-width: 15rem;

    &:hover {
      background-color: $buttonHoverBg;
      box-shadow: rgba(243, 114, 55, 0.6) 3px 3px,
        rgba(243, 114, 55, 0.3) 6px 6px, rgba(243, 114, 55, 0.2) 9px 9px;
      border: 2px solid $buttonHoverBd;
      cursor: pointer;
    }
  }
}

.submit-form__item-description,
.submit-form__item-title {
  width: 100%;
  background-color: $formBlue;
  border-radius: 25px;
  border: 2px solid $formBlue;
  padding: 1.2rem 2.6rem;
  font-family: "QuickSand";
  font-size: 1.6rem;
  margin-bottom: 2.6rem;
  &:focus {
    border: 2px solid $darkBlue;
    outline: none;
  }
}

.submit-form__item-description {
  resize: none;
  height: 13rem;
}

.why-text {
  color: $blueText;
  line-height: 2.2rem;
  text-align: center;
  margin: -1.2rem 0 3.6rem 0;
  width: 90%;
}

.image-container {
  padding: 0 1.2rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin: 2.4rem 0 2.4rem 0;
}

.image-upload-preview {
  margin: 2.4rem 0 2.4rem 0;
  width: 30%;
  border-radius: 3px;
  border: 2px solid $blueText;
  box-shadow: rgba(60, 135, 165, 0.4) 4px 4px;
}
.image-upload-input {
  display: none;
}

.image-upload-label {
  height: 4rem;
  margin-bottom: 3.4rem;
  width: 30%;
  color: $blueText;
  padding-top: 0.8rem;
  text-align: center;
  background-color: white;
  border-radius: 25px;
  border: 2px solid $blueText;
  font-family: "QuickSand";
  font-size: 1.6rem;
  min-width: 15rem;

  &:hover {
    box-shadow: rgba(60, 135, 165, 0.6) 3px 3px, rgba(60, 135, 165, 0.3) 6px 6px,
      rgba(60, 135, 165, 0.2) 9px 9px;
    cursor: pointer;
  }
}
